import request from "./request";

const PracticalService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/practical/all/${id}`, context.token);
  },
  BulkSave: async (payload, context) => {
    return await request.sendPostRequest(`/practical/bulk`, payload, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/practical`, payload, context);
  },
  SaveScore: async (payload, context) => {
    return await request.sendPostRequest(`/practical/score`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/practical/${id}`, context.token);
  },
};

export default PracticalService;
