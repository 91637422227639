import request from "./request";

const AssessmentMasterService = {
  GetAll: async (context) => {
    return await request.sendRequest(`/assessmentmaster/all`, context.token);
  },
  Get: async (id, context) => {
    return await request.sendRequest(`/assessmentmaster/${id}`, context.token);
  },
  GetAttendees: async (id, searchText, context) => {
    return await request.sendRequest(`/assessmentmaster/mcq/attendees/-1/0/${id}/${searchText}`, context.token);
  },
  GetPracticalAttendees: async (id, searchText, context) => {
    return await request.sendRequest(`/assessmentmaster/practical/attendees/-1/0/${id}/${searchText}`, context.token);
  },
  GetSessions: async (id, searchText, context) => {
    return await request.sendRequest(`/assessmentmaster/mcq/sessions/${id}/${searchText}`, context.token);
  },
  GetPracticalSessions: async (id, searchText, context) => {
    return await request.sendRequest(`/assessmentmaster/practical/sessions/${id}/${searchText}`, context.token);
  },
  ImportMCQ: async (id, payload, context) => {
    return await request.sendFormDataRequest(`/mcq/import/${id}`, payload, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/assessmentmaster`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/assessmentmaster/${id}`, context.token);
  },
};

export default AssessmentMasterService;
