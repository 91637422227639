import { Fragment } from "react";

const PCard = ({ id, data, clickHandler }) => {
  return (
    <Fragment>
      <div className="quiz-container cursor-pointer m-auto border" onClick={() => clickHandler(id)}>
        <div className="title">{data.attendeeName}</div>
        {data.isPracticalExamCompleted &&
          <>
            <div className="score-container pt-3">
              <center>
                <span>Score : <label className="score">{data.isPracticalMarksCalculated ? `${data.practicalScore} / ${data.totalPracticalScore}` : 'Not Calculated'}</label></span>
              </center>
            </div>
          </>
        }
      </div>
    </Fragment>
  );
};

export default PCard;
