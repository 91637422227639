import request from "./request";

const MCQExamService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/mcqexam/all/${id}`, context.token);
  },
  Start: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/start/${id}`, context);
  },
  Cancel: async (payload, context) => {
    return await request.sendPostRequest(`/mcqexam/cancel`, payload, context);
  },
  CancelPractical: async (payload, context) => {
    return await request.sendPostRequest(`/mcqexam/cancel/practical`, payload, context);
  },
  CalculateScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/calculate/${id}`, context);
  },
  RecalculateScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/re-calculate/${id}`, context);
  },
  CalculatePracticalScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/calculate/practical/${id}`, context);
  },
  RecalculatePracticalScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/re-calculate/practical/${id}`, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/mcqexam`, payload, context);
  },
  RemoveSession: async (assessmentId, userId, context) => {
    return await request.sendDeleteRequest(`/mcqexam/session/${assessmentId}/${userId}`, context.token);
  },
};

export default MCQExamService;
