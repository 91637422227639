import request from "./request";

const PracticalExamService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/practicalexam/all/${id}`, context.token);
  },
  GetFile: async (id, context) => {
    return await request.sendRequest(`/practicalexam/file/${id}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/practicalexam`, payload, context);
  },
  SaveDraft: async (payload, context) => {
    return await request.sendFormDataRequest(`/practicalexam/draft`, payload, context);
  },
};

export default PracticalExamService;
