import React from "react";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const DocumentViewer = ({ src, contentType }) => {
  let url = '';
  // const [numPages, setNumPages] = useState(null);

  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  // const base64WithoutPrefix = src.substr('data:application/pdf;base64,'.length);
  if (src && src !== null) {
    const base64WithoutPrefix = src;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    const blob = new Blob([out], { type: contentType });
    url = URL.createObjectURL(blob);
  }
  return (
    <center>
      <Viewer fileUrl={url} />
    </center>);
};

export default DocumentViewer;
