import { Fragment, useContext } from "react";
import Moment from 'moment';
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import MCQExamService from '../../../../services/mcqexam.service'
import AuthContext from "../../../../auth/auth-context";
import AlertService from "../../../../services/alert.service";

const SessionCard = ({ id, data, refreshHandler }) => {

  const authContext = useContext(AuthContext);
  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this session?.",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: deleteHandler,
    });
  };

  const deleteHandler = async () => {
    let result = await MCQExamService.RemoveSession(id, data.userId, authContext);
    if (result.isSuccess) {
      AlertService.success("Session details are deleted successfully!! Please ask the user to restart the exam.");
      refreshHandler();
    } else {
      AlertService.error(result.errorMessage);
    }
  }

  return (
    <Fragment>
      <div className="quiz-container cursor-pointer m-auto border">
        <div className="title">{data.attendeeName}</div>
        <div className="score-container pt-3">
          <center>
            <span>Started Date & Time</span>
          </center>
        </div>
        <div className="score-container pt-3">
          <center>
            <span><label className="score">{Moment(Moment.utc(data.startDate).toDate()).format("DD/MM/YYYY hh:mm:ss A")}</label></span>
          </center>
        </div>
        <div className="pt-2">
          <center>
            <ConfirmPopup />
            <span><button className="score btn btn-danger" onClick={onConfirmDeleteHandler}><i className="fa fa-trash"></i> Kill Session</button></span>
          </center>
        </div>
      </div>
    </Fragment>
  );
};

export default SessionCard;
