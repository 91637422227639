import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Chips } from 'primereact/chips';
import { Tree } from "primereact/tree";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import AuthContext from "../../../auth/auth-context";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import CourseService from "../../../services/course.service";
import "../identity.css";
import { useNavigate } from "react-router-dom";

const Learners = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(0);

  const [userId, setUserId] = useState(0);
  const [email, setEmail] = useState();
  const [displayName, setDisplayName] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [gender, setGender] = useState();
  const [dob, setDOB] = useState();
  const [nationality, setNationality] = useState();
  const [address, setAddress] = useState();
  const [courses, setCourses] = useState();
  const [visible, setVisible] = useState(false);
  const [mfaEnabled, setMFAEnabled] = useState(true);
  const [courseId, setCourseId] = useState();
  const [allCourses, setAllCourses] = useState();
  const [filteredCourses, setFilteredCourses] = useState();
  const [tags, setTags] = useState();

  const authContext = useContext(AuthContext);

  const assessmentAccess = authContext.getPermissions("learners");

  if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }
  const showAdd = assessmentAccess.filter((f) => f.access.includes(2)).length > 0;
  const showEdit = assessmentAccess.filter((f) => f.access.includes(3)).length > 0;
  const showDelete = assessmentAccess.filter((f) => f.access.includes(4)).length > 0;

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [displayNameIsValid, setDisplayNameIsValid] = useState(true);

  const emailRef = useRef();
  const displayNameRef = useRef();
  const mobileNoRef = useRef();
  const genderRef = useRef();
  const nationalityRef = useRef();
  const addressRef = useRef();

  const assignUser = async (e) => {
    if (users.filter((f) => f.id === e).length > 0) {
      setEmailIsValid(true);
      setDisplayNameIsValid(true);
      let selectedUserData = users.filter((f) => f.id === e)[0];
      setUserId(selectedUserData.data.id);
      setEmail(selectedUserData.data.email);
      setDisplayName(selectedUserData.data.displayName);
      setMobileNo(selectedUserData.data.mobileNo);
      setGender(selectedUserData.data.gender);
      setDOB(new Date(selectedUserData.data.dateOfBirth));
      setNationality(selectedUserData.data.nationality);
      setMFAEnabled(selectedUserData.data.mfaSetupEnabled);
      setAddress(selectedUserData.data.address);
      setTags(selectedUserData.data.tags && selectedUserData.data.tags !== null && selectedUserData.data.tags !== '' ? selectedUserData.data.tags.split(', ') : null);
      emailRef.current.value = selectedUserData.data.email;
      displayNameRef.current.value = selectedUserData.data.displayName;
      mobileNoRef.current.value = selectedUserData.data.mobileNo;
      genderRef.current.value = selectedUserData.data.gender;
      nationalityRef.current.value = selectedUserData.data.nationality;
      addressRef.current.value = selectedUserData.data.address;
    }
  };

  const loadAllCourses = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.GetCourses(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setAllCourses(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  const loadUsers = useCallback(async () => {
    setLoading(true);
    const data = await UserService.GetAllLearners(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setUsers(
        data.data.map((m) => ({
          id: m.id,
          key: m.id,
          label: m.displayName,
          data: m,
        }))
      );
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers, authContext]);

  useEffect(() => {
    loadAllCourses();
  }, [loadAllCourses, authContext]);

  const clearData = () => {
    setUserId(0);
    setEmail(null);
    setDisplayName(null);
    setMobileNo(null);
    setGender(null);
    setDOB(null);
    setNationality(null);
    setAddress(null);
    setEmailIsValid(true);
    setDisplayNameIsValid(true);
    setMFAEnabled(true);
    setCourses([]);
    setFilteredCourses([]);
    setTags(null);
    emailRef.current.value = "";
    displayNameRef.current.value = "";
    mobileNoRef.current.value = "";
    genderRef.current.value = "";
    nationalityRef.current.value = "";
    addressRef.current.value = "";
    setSelectedUser(0);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let emailValue = emailRef.current.value;
    let displayNameValue = displayNameRef.current.value;
    let mobileNoValue = mobileNoRef.current.value;
    let genderValue = genderRef.current.value;
    let dobValue = dob;
    let nationalityValue = nationalityRef.current.value;
    let addressValue = addressRef.current.value;

    if (!emailValue) {
      setEmailIsValid(false);
    }
    if (!displayNameValue) {
      setDisplayNameIsValid(false);
    }
    if (
      emailValue &&
      displayNameValue
    ) {
      setLoading(true);
      try {
        let payload = {
          instanceId: 1,
          userName: emailValue,
          email: emailValue,
          displayName: displayNameValue,
          mobileNo: mobileNoValue,
          gender: genderValue,
          dateOfBirth: dobValue,
          nationality: nationalityValue,
          mfaSetupEnabled: mfaEnabled,
          address: addressValue,
          tags: tags && tags.length > 0 ? tags.join(', ') : ''
        };
        if (userId > 0) {
          payload.id = userId;
        }
        let data = await UserService.SaveLearner(payload, authContext);
        if (data.isSuccess) {
          clearData();
          await loadUsers();
          AlertService.success("Learner details saved successfully!");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete the selected learner?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await UserService.Remove(selectedUser, authContext);
    if (data.isSuccess) {
      clearData();
      await loadUsers();
      AlertService.success("Learner details deleted successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onResetMFAHandler = async () => {
    let data = await UserService.ResetMFA(selectedUser, authContext);
    if (data.isSuccess) {
      AlertService.success("MFA reset completed successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onResetPasswordHandler = async () => {
    let data = await UserService.Reset(selectedUser, authContext);
    if (data.isSuccess) {
      AlertService.sticky(`New Password generated. Password: ${data.data}`);
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onCancelHandler = async (e) => {
    e.preventDefault();
    clearData();
  };

  const loadCourses = async (id) => {
    setCourses([]);
    if (id && id != null) {
      let data = await CourseService.GetUserCourses(id, authContext);
      if (data.isSuccess) {
        setCourses(data.data);
      }
    }
  };

  const onAddHandler = async (e) => {
    e.preventDefault();
    setFilteredCourses(allCourses.filter(f => courses.filter(c => c.value === f.value).length === 0));
    setVisible(true);
  };

  const addCourseHandler = async (e) => {
    e.preventDefault();
    if (courseId && courseId > 0 && selectedUser && selectedUser > 0) {
      let payload = { userId: selectedUser, courseId: courseId };
      let data = await CourseService.SaveUser(payload, authContext);
      if (data.isSuccess) {
        loadCourses(selectedUser);
        setFilteredCourses([]);
        setVisible(false);
        setCourseId(0);
      } else {
        AlertService.error(data.errorMessage);
      }
    } else {
      AlertService.error('Please select a course!!');
    }
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <h2>Users <a href="#/learners/upload" title='Upload Learners'><i className="fa fa-upload" ></i></a> <a href="assets/Learners Import Template.xlsx" title='Download Excel Template'><i className="fa fa-download" ></i></a> {showDelete && <a className="text-danger" href="#/learners/remove" title='Remove Learners'><i className="fa fa-trash" ></i></a>}
          <input type="file" className="d-none"></input>
        </h2>
        <div className="row m-0">
          <div className="col-lg-3 d-none d-lg-block">
            <Tree
              value={users}
              selectionMode="single"
              selectionKeys={selectedUser}
              onSelectionChange={(e) => {
                setSelectedUser(e.value);
                assignUser(e.value);
                loadCourses(e.value);
              }}
              className="w-full"
              loading={loading}
            />
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="row pt-4 d-block d-lg-none">
              <div className="col-12">
                <select
                  className="form-control"
                  value={selectedUser}
                  onChange={(e) => {
                    setSelectedUser(Number(e.target.value));
                    assignUser(Number(e.target.value));
                    loadCourses(Number(e.target.value));
                  }}
                >
                  {users &&
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <form onSubmit={onSubmitHandler}>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Email</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    maxLength="150"
                    defaultValue={email}
                    ref={emailRef}
                    onChange={() => setEmailIsValid(true)}
                  />
                  {!emailIsValid && (
                    <p className="error">Please provide email</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Display Name
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Display Name"
                    maxLength="150"
                    defaultValue={displayName}
                    ref={displayNameRef}
                    onChange={() => setDisplayNameIsValid(true)}
                  />
                  {!displayNameIsValid && (
                    <p className="error">Please provide display name</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Mobile No
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile No"
                    maxLength="150"
                    defaultValue={mobileNo}
                    ref={mobileNoRef}
                  />
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Gender</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <select
                    className="form-control"
                    placeholder="Gender"
                    defaultValue={gender}
                    ref={genderRef}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="C">Cross-Gender</option>
                    <option value="N">Prefer not to say</option>
                  </select>
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Date of Birth
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Calendar
                    value={dob}
                    onChange={(e) => setDOB(e.value)}
                    showIcon
                    className="w-full"
                  />
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Nationality
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <select
                    className="form-control"
                    placeholder="Nationality"
                    defaultValue={nationality}
                    ref={nationalityRef}
                  >
                    <option value="Indian">Indian</option>
                    <option value="NRI">Non-Residential Indian</option>
                    <option value="Foreigner">Foreigner</option>
                  </select>
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Address</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    maxLength="500"
                    defaultValue={address}
                    ref={addressRef}
                  />
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Tags</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Chips addOnBlur={true} allowDuplicate={false} value={tags} onChange={(e) => setTags(e.value)} />
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Checkbox
                    inputId="mcqId"
                    onChange={(e) => setMFAEnabled(e.checked)}
                    checked={mfaEnabled}
                  ></Checkbox><label htmlFor="mcqId" className="ml-2 mt-1 mr-2"> MFA Enabled</label>
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              {courses && courses.length > 0 &&
                <div className="row pt-4">
                  <div className="col-md-1 col-sm-2 d-xs-none"></div>
                  <div className="col-md-2 col-sm-3 col-xs-12 label">Courses <button type="button" title="Click here to add course to the selected user" className="btn btn-icon" onClick={onAddHandler}><i className="fa fa-plus-circle"></i></button></div>
                  <div className="col-md-8 col-sm-5 col-xs-12 value">
                    <div className="attachment-container">
                      {courses.map((m, i) => (
                        <div key={i} className="attachment">
                          {i + 1}.{" "}
                          <span className="btn-icon" >
                            {m.label}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-1 col-sm-2 d-xs-none"></div>
                </div>
              }
              <div className="row pt-2 mb-3">
                <div className="col-md-12 col-sm-12">
                  <div className="text-center pt-4">
                    {showAdd &&
                      selectedUser === 0 &&
                      <button
                        type="submit"
                        className="submit-button"
                        disabled={loading}
                      >
                        Submit
                      </button>
                    }
                    {showEdit &&
                      selectedUser > 0 &&
                      <button
                        type="submit"
                        className="submit-button"
                        disabled={loading}
                      >
                        Submit
                      </button>
                    }
                    <ConfirmPopup />
                    {
                      showDelete &&
                      selectedUser > 0 && (
                        <button
                          type="button"
                          className="remove-button ml-3"
                          onClick={onConfirmDeleteHandler}
                          disabled={loading}
                        >
                          Delete
                        </button>
                      )}
                    {
                      showEdit &&
                      selectedUser > 0 && (
                        <button
                          type="button"
                          className="cancel-button ml-3"
                          onClick={onResetMFAHandler}
                          disabled={loading}
                        >
                          Reset MFA
                        </button>
                      )}
                    {
                      showEdit &&
                      selectedUser > 0 && (
                        <button
                          type="button"
                          className="cancel-button ml-3"
                          onClick={onResetPasswordHandler}
                          disabled={loading}
                        >
                          Reset Password
                        </button>
                      )}
                    <button
                      type="button"
                      className="cancel-button ml-3"
                      onClick={onCancelHandler}
                      disabled={loading}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Dialog header="Add Course" visible={visible} position={'bottom'} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
        <p className="m-0">
          <Dropdown value={courseId} onChange={(e) => setCourseId(e.value)} options={filteredCourses} optionLabel="label"
            placeholder="Select a course" className="w-full" />
        </p>
        <p className="m-0 mt-3">
          <button type="button" className="cancel-button ml-3 float-right" onClick={() => { setFilteredCourses([]); setVisible(false); setCourseId(0); }}>Cancel</button>
          <button type="button" className="submit-button float-right" onClick={addCourseHandler}>Add</button>
        </p>
      </Dialog>
    </Fragment>
  );
};

export default Learners;
