import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../auth/auth-context";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import "./users.css";

const ImpersonateUsers = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');

  const authContext = useContext(AuthContext);

  const loadUsers = useCallback(async () => {
    setLoading(true);
    const result = await UserService.GetImpersonateAll(searchText, authContext);
    if (result.isSuccess) {
      setLoading(false);
      setUsers(result.data);
    } else {
      setLoading(false);
      AlertService.error(result.errorMessage);
    }
  }, [searchText, authContext]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers, searchText, authContext]);

  const onConfirmImpersonateHandler = (event, user) => {
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to impersonate the selected user?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: async () => await onImpersonateHandler(user.id),
    });
  };

  const onImpersonateHandler = async (selectedUser) => {
    let result = await UserService.ImpersonateUser(selectedUser, authContext);
    if (result.isSuccess) {
      AlertService.success("User impersonated successfully!");
      authContext.login(
        result.data.access_token,
        result.data.refresh_token,
        result.data.id,
        result.data.roleId,
        result.data.isAnswered,
        result.data.resetPassword,
        result.data.mfaSetupCompleted,
        result.data.mfaSetupEnabled,
        true,
        result.data.hasOngoingValidations,
        result.data.displayName,
        result.data.menuPermissions,
        result.data.course_count,
        result.data.impersonate,
        result.data.impersonatedUserId
      );
      authContext.setPermissions(result.data.menuPermissions);
    } else {
      AlertService.error(result.errorMessage);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <h2>Users</h2>
          </div>
          <div className="col-5"></div>
          <div className="col-4">
            <div className="form-group has-search search-group catalogue-search">
              <span className="fa fa-search form-control-feedback"></span>
              <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" placeholder="Search" />
              {searchText && <span className="input-group-append">
                <button className="btn border-0" type="button" onClick={() => setSearchText('')}>
                  <i className="fa fa-times"></i>
                </button>
              </span>}
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className='col-12'>
            <div className="users">
              <div className="row m-0">
                <div className="col-3 text-center header-cell">Username</div>
                <div className="col-3 text-center header-cell">Display Name</div>
                <div className="col-3 text-center header-cell">Mobile No</div>
                <div className="col-2 text-center header-cell">Gender</div>
                <div className="col-1 text-center header-cell"></div>
              </div>
              <div className="scroller">
                {users &&
                  users.length > 0 &&
                  users.map((userObj, i) => (
                    <div key={i} className="row m-0 records">
                      <div className="col-3 value-cell">{userObj.userName}</div>
                      <div className="col-3 value-cell">{userObj.displayName}</div>
                      <div className="col-3 value-cell">{userObj.mobileNo}</div>
                      <div className="col-2 value-cell">{userObj.gender === 'F' ? 'Female' : userObj.gender === 'C' ? 'Cross-Gender' : userObj.gender === 'N' ? 'Prefer not to say' : 'Male'}</div>
                      <div className="col-1 value-cell"><button className="btn btn-primary" onClick={(e) => onConfirmImpersonateHandler(e, userObj)}>Impersonate</button></div>
                    </div>
                  ))}
                {users && users.length === 0 && (
                  <div className="row m-0 no-records">
                    <div className="col-12 value-cell text-center">
                      {loading ? "Loading..." : "No users found"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ConfirmPopup />
        </div>
      </div>
    </Fragment>
  );
};

export default ImpersonateUsers;
