import { Fragment, useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import PracticalExamService from "../../../services/practicalexam.service";
import AlertService from "../../../services/alert.service";

const PracticalExamQuestion = ({
    index,
    selectedIndex,
    input,
    changeHandler,
    uploadStatusHandler
}) => {
    const assessmentId = (input && input.assessmentId) ?? 0;
    const questionId = (input && input.id) ?? 0;
    const storyBoardTitle = input && input.storyBoardTitle;
    const storyBoardInfo = input && input.storyBoardInfo;
    const taskTitle = input && input.taskTitle;
    const taskInfo = input && input.taskInfo;
    const [fileName, setFileName] = useState(input && input.fileName);
    const [file, setFile] = useState(input && input.file);
    const fileUploadRef = useRef();
    const authContext = useContext(AuthContext);
    const fileSizeLimit = 2 * 1024 * 1024;

    const handleChange = async (event) => {
        if (event.target.files.length > 0) {
            uploadStatusHandler(index, true);
            const formData = new FormData();
            let file = event.target.files[0];
            if (file.size > fileSizeLimit) {
                setFile(null);
                setFileName(null);
                AlertService.error('File size cannot exceed 2MB!');
            } else {
                setFile(file);
                setFileName(file.name);
                formData.append("files", file);
                formData.append(`userId`, authContext.userId);
                formData.append(`assessmentId`, assessmentId);
                formData.append(`questionId`, questionId);
                formData.append(`fileName`, file.name);
                formData.append(`contentType`, file.type);
                let result = await PracticalExamService.SaveDraft(formData, authContext);
                if (!result.isSuccess) {
                    setFile(null);
                    setFileName(null);
                    AlertService.error('Upload failed. Please contact support!');
                }
            }
            uploadStatusHandler(index, false);
        }
        setTimeout(() => {
            fileUploadRef.current.value = "";
        }, 100);
    };

    useEffect(() => {
        let payload = {
            id: questionId,
            assessmentId: assessmentId,
            storyBoardTitle: storyBoardTitle,
            storyBoardInfo: storyBoardInfo,
            taskTitle: taskTitle,
            taskInfo: taskInfo,
            file: file,
            fileName: fileName
        };
        changeHandler(index, payload);
    }, [index, assessmentId, storyBoardTitle, storyBoardInfo, taskTitle, taskInfo]);

    return (
        <Fragment>
            <div className={selectedIndex === index ? 'd-block' : 'd-none'}>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{storyBoardTitle}</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{storyBoardInfo}</div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{taskTitle}</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{taskInfo}</div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Select File ( <span className="text-danger">Acceptable file size limited to 2 MB only</span> )</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="input-group">
                            <div className="custom-file">
                                <input
                                    ref={fileUploadRef}
                                    type="file"
                                    className="custom-file-input"
                                    onChange={handleChange}
                                    accept="application/pdf"
                                    id='fileuploaddocument'
                                ></input>
                                <label className="custom-file-label" htmlFor="fileuploaddocument">
                                    Choose file
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10 value document-files">
                        <div className="attachment-container">
                            {
                                fileName && fileName !== "" &&
                                <div className="file">
                                    {fileName}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PracticalExamQuestion;
