import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Chips } from 'primereact/chips';
import { Tree } from "primereact/tree";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Calendar } from "primereact/calendar";
import AuthContext from "../../../auth/auth-context";
import RoleService from "../../../services/role.service";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [userId, setUserId] = useState(0);
  const [email, setEmail] = useState();
  const [displayName, setDisplayName] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [gender, setGender] = useState();
  const [dob, setDOB] = useState();
  const [nationality, setNationality] = useState();
  const [address, setAddress] = useState();
  const [role, setRole] = useState();
  const [tags, setTags] = useState();

  const authContext = useContext(AuthContext);

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [displayNameIsValid, setDisplayNameIsValid] = useState(true);
  const [mobileNoIsValid, setMobileNoIsValid] = useState(true);
  const [genderIsValid, setGenderIsValid] = useState(true);
  const [dobIsValid, setDOBIsValid] = useState(true);
  const [nationalityIsValid, setNationalityIsValid] = useState(true);
  const [roleIsValid, setRoleIsValid] = useState(true);

  const emailRef = useRef();
  const displayNameRef = useRef();
  const mobileNoRef = useRef();
  const genderRef = useRef();
  const nationalityRef = useRef();
  const addressRef = useRef();
  const roleRef = useRef();

  const assignUser = async (e) => {
    if (users.filter((f) => f.id === e).length > 0) {
      setEmailIsValid(true);
      setDisplayNameIsValid(true);
      setMobileNoIsValid(true);
      setGenderIsValid(true);
      setRoleIsValid(true);
      setDOBIsValid(true);
      setNationalityIsValid(true);
      let selectedUserData = users.filter((f) => f.id === e)[0];
      setUserId(selectedUserData.data.id);
      setEmail(selectedUserData.data.email);
      setDisplayName(selectedUserData.data.displayName);
      setMobileNo(selectedUserData.data.mobileNo);
      setGender(selectedUserData.data.gender);
      setRole(selectedUserData.data.roleId);
      setDOB(new Date(selectedUserData.data.dateOfBirth));
      setNationality(selectedUserData.data.nationality);
      setAddress(selectedUserData.data.address);
      setTags(selectedUserData.data.tags && selectedUserData.data.tags !== null && selectedUserData.data.tags !== '' ? selectedUserData.data.tags.split(', ') : null);
      emailRef.current.value = selectedUserData.data.email;
      displayNameRef.current.value = selectedUserData.data.displayName;
      mobileNoRef.current.value = selectedUserData.data.mobileNo;
      genderRef.current.value = selectedUserData.data.gender;
      roleRef.current.value = selectedUserData.data.roleId;
      nationalityRef.current.value = selectedUserData.data.nationality;
      addressRef.current.value = selectedUserData.data.address;
    }
  };

  const loadUsers = useCallback(async () => {
    setLoading(true);
    const data = await UserService.GetAllExceptLearners(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setUsers(
        data.data.map((m) => ({
          id: m.id,
          key: m.id,
          label: m.displayName,
          data: m,
        }))
      );
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  const loadRoles = useCallback(async () => {
    setLoading(true);
    const data = await RoleService.GetAll(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setRoles(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadUsers();
    loadRoles();
  }, [loadUsers, loadRoles, authContext]);

  const clearData = () => {
    setUserId(0);
    setEmail(null);
    setDisplayName(null);
    setMobileNo(null);
    setGender(null);
    setRole(null);
    setDOB(null);
    setNationality(null);
    setAddress(null);
    setEmailIsValid(true);
    setDisplayNameIsValid(true);
    setMobileNoIsValid(true);
    setGenderIsValid(true);
    setRoleIsValid(true);
    setDOBIsValid(true);
    setNationalityIsValid(true);
    setTags(null);
    emailRef.current.value = "";
    displayNameRef.current.value = "";
    mobileNoRef.current.value = "";
    genderRef.current.value = "";
    roleRef.current.value = "";
    nationalityRef.current.value = "";
    addressRef.current.value = "";
    setSelectedUser(0);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let emailValue = emailRef.current.value;
    let displayNameValue = displayNameRef.current.value;
    let mobileNoValue = mobileNoRef.current.value;
    let genderValue = genderRef.current.value;
    let roleValue = roleRef.current.value;
    let dobValue = dob;
    let nationalityValue = nationalityRef.current.value;
    let addressValue = addressRef.current.value;

    if (!emailValue) {
      setEmailIsValid(false);
    }
    if (!displayNameValue) {
      setDisplayNameIsValid(false);
    }
    if (!mobileNoValue) {
      setMobileNoIsValid(false);
    }
    if (!genderValue) {
      setGenderIsValid(false);
    }
    if (!roleValue) {
      setRoleIsValid(false);
    }
    if (!dobValue) {
      setDOBIsValid(false);
    }
    if (!nationalityValue) {
      setNationalityIsValid(false);
    }
    if (
      emailValue &&
      displayNameValue &&
      mobileNoValue &&
      genderValue &&
      roleValue &&
      dobValue &&
      nationalityValue
    ) {
      setLoading(true);
      try {
        let payload = {
          instanceId: 1,
          userName: emailValue,
          email: emailValue,
          displayName: displayNameValue,
          mobileNo: mobileNoValue,
          gender: genderValue,
          roleId: roleValue,
          dateOfBirth: dobValue,
          nationality: nationalityValue,
          address: addressValue,
          tags: tags && tags.length > 0 ? tags.join(', ') : ''
        };
        if (userId > 0) {
          payload.id = userId;
        }
        let data = await UserService.Save(payload, authContext);
        if (data.isSuccess) {
          clearData();
          await loadUsers();
          AlertService.success("User details saved successfully!");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete the selected user?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await UserService.Remove(selectedUser, authContext);
    if (data.isSuccess) {
      clearData();
      await loadUsers();
      AlertService.success("User details deleted successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onResetMFAHandler = async () => {
    let data = await UserService.ResetMFA(selectedUser, authContext);
    if (data.isSuccess) {
      AlertService.success("MFA reset completed successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onCancelHandler = async (e) => {
    e.preventDefault();
    clearData();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <h2>Users</h2>
        <div className="row m-0">
          <div className="col-lg-3 d-none d-lg-block">
            <Tree
              value={users}
              selectionMode="single"
              selectionKeys={selectedUser}
              onSelectionChange={(e) => {
                setSelectedUser(e.value);
                assignUser(e.value);
              }}
              className="w-full"
              loading={loading}
            />
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="row pt-4 d-block d-lg-none">
              <div className="col-12">
                <select
                  className="form-control"
                  value={selectedUser}
                  onChange={(e) => {
                    setSelectedUser(Number(e.target.value));
                    assignUser(Number(e.target.value));
                  }}
                >
                  {users &&
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <form onSubmit={onSubmitHandler}>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Email</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    maxLength="150"
                    defaultValue={email}
                    ref={emailRef}
                    onChange={() => setEmailIsValid(true)}
                  />
                  {!emailIsValid && (
                    <p className="error">Please provide email</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Display Name
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Display Name"
                    maxLength="150"
                    defaultValue={displayName}
                    ref={displayNameRef}
                    onChange={() => setDisplayNameIsValid(true)}
                  />
                  {!displayNameIsValid && (
                    <p className="error">Please provide display name</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Mobile No
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile No"
                    maxLength="150"
                    defaultValue={mobileNo}
                    ref={mobileNoRef}
                    onChange={() => setMobileNoIsValid(true)}
                  />
                  {!mobileNoIsValid && (
                    <p className="error">Please provide mobile no</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Gender</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <select
                    className="form-control"
                    placeholder="Gender"
                    defaultValue={gender}
                    ref={genderRef}
                    onChange={() => setGenderIsValid(true)}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="C">Cross-Gender</option>
                    <option value="N">Prefer not to say</option>
                  </select>
                  {!genderIsValid && (
                    <p className="error">Please select gender</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Date of Birth
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Calendar
                    value={dob}
                    onChange={(e) => setDOB(e.value)}
                    showIcon
                    className="w-full"
                  />
                  {!dobIsValid && (
                    <p className="error">Please provide date of birth</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Nationality
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <select
                    className="form-control"
                    placeholder="Role"
                    defaultValue={nationality}
                    ref={nationalityRef}
                    onChange={() => setNationalityIsValid(true)}
                  >
                    <option value="Indian">Indian</option>
                    <option value="NRI">Non-Residential Indian</option>
                    <option value="Foreigner">Foreigner</option>
                  </select>
                  {!nationalityIsValid && (
                    <p className="error">Please provide nationality</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Address</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    maxLength="500"
                    defaultValue={address}
                    ref={addressRef}
                  />
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Role</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <select
                    className="form-control"
                    placeholder="Role"
                    defaultValue={role}
                    ref={roleRef}
                    onChange={() => setRoleIsValid(true)}
                  >
                    {roles &&
                      roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.roleName}
                        </option>
                      ))}
                  </select>
                  {!roleIsValid && <p className="error">Please select role</p>}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Tags</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Chips value={tags} onChange={(e) => setTags(e.value)} />
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-2 mb-3">
                <div className="col-md-12 col-sm-12">
                  <div className="text-center pt-4">
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={loading}
                    >
                      Submit
                    </button>
                    <ConfirmPopup />
                    {selectedUser > 0 && (
                      <button
                        type="button"
                        className="remove-button ml-3"
                        onClick={onConfirmDeleteHandler}
                        disabled={loading}
                      >
                        Delete
                      </button>
                    )}
                    {selectedUser > 0 && (
                      <button
                        type="button"
                        className="cancel-button ml-3"
                        onClick={onResetMFAHandler}
                        disabled={loading}
                      >
                        Reset MFA
                      </button>
                    )}
                    <button
                      type="button"
                      className="cancel-button ml-3"
                      onClick={onCancelHandler}
                      disabled={loading}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Users;
