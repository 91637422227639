import "../app.css";
import '../masters/quiz/quiz.css'
import AlertService from "../../../services/alert.service";
import PracticalExamService from "../../../services/practicalexam.service";
import AuthContext from "../../../auth/auth-context";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import PracticalExamQuestion from "./practicalquestion";

const PracticalExam = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [assessmentExam, setAssessmentExam] = useState();
    const [questions, setQuestions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [isNavigated, setIsNavigated] = useState(false);
    const authContext = useContext(AuthContext);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const scrollRef = useRef();

    const assessmentAccess = authContext.getPermissions("assessments");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await PracticalExamService.GetAll(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessmentExam(data.data);
            if (data.data.isPracticalExamCompleted) {
                navigate(`/assessments/${params.id}`);
            }
            setQuestions(data.data.questions);
            if (data.data.questions.length > 0) {
                setSelectedCardId(data.data.questions[0].id);
            }
            setSelectedIndex(0);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, navigate, params.id]);

    useEffect(() => {
        if (params.id) {
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const changeHandler = (idx, input) => {
        setQuestions(prev => {
            if (prev && prev.length > 0) {
                prev[idx] = input;
            }
            return [...prev];
        });
    };

    const onCardClick = (data, i) => {
        let preId = selectedCardId;
        setSelectedCardId(data.id);
        setSelectedIndex(i);
        if (preId === undefined || preId === null || preId === 0) {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
        }
    }

    const moveToPrevious = (e) => {
        setIsNavigated(true);
        setSelectedIndex(prev => {
            if (prev > 0) {
                return prev - 1;
            } else {
                return 0;
            }
        });
    }

    const moveToNext = (e) => {
        setIsNavigated(true);
        setSelectedIndex(prev => {
            if (prev === (questions.length - 1)) {
                return prev;
            } else {
                return prev + 1;
            }
        });
    }

    useEffect(() => {
        if (questions && questions.length > 0 && isNavigated) {
            setSelectedCardId(questions[selectedIndex].id);
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
            setIsNavigated(false);
        }
    }, [isNavigated, selectedIndex, questions]);

    const onSubmitHandler = async () => {
        setLoading(true);
        try {
            let payload = assessmentExam;
            payload.quizId = params.id;
            let data = await PracticalExamService.Save(payload, authContext);
            if (data.isSuccess) {
                AlertService.success("Assessment details saved successfully!");
                navigate(`/assessments/${params.id}`);
            } else {
                AlertService.error(data.errorMessage);
            }
            setLoading(false);
        } catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    };

    const onConfirmSubmitHandler = (event) => {
        setAssessmentExam(prev => {
            prev.questions = questions;
            return prev;
        });
        confirmPopup({
            target: event.currentTarget,
            message: "Are your sure you willing to submit the Assessment",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onSubmitHandler,
        });
    };

    const onUploadStatusHandler = (index, status) => {
        setUploading(status);
    }

    return (
        <Fragment>
            <div className="container-fluid question-container">
                <div className="row d-none d-md-flex">
                    <div className="col-md-2 col-sm-4">
                        <center className="quiz-left-title">Practical</center>
                        <div className="incident-list incident-v-scroll border">
                            <div className="row m-0">
                                {
                                    questions && questions.length > 0 &&
                                    questions.map((q, i) => (
                                        <div key={i} className="col-12" ref={q.id === selectedCardId ? scrollRef : null}>
                                            <div
                                                className={`quiz-card p-1 text-center ${q.id === selectedCardId ? 'active' : ''} ${uploading ? 'btn-processing' : ''}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (!uploading) {
                                                        onCardClick(q, i);
                                                    }
                                                }}
                                            >
                                                <div className="name" title={q.question}>
                                                    Question {i + 1}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-sm-8 view">
                        <div className="row m-0">
                            <div className="col-4 course-title">
                            </div>
                            <div className="col-8 course-title">
                                <span className="quiz-title">{assessmentExam && assessmentExam.assessmentName}</span>
                            </div>
                        </div>
                        {!loading && questions && questions.length > 0 && questions.map((m, i) => (
                            <PracticalExamQuestion key={i} index={i} input={m} selectedIndex={selectedIndex} changeHandler={changeHandler} uploadStatusHandler={onUploadStatusHandler}></PracticalExamQuestion>
                        ))}
                        {loading && <div className="row m-0 pl-3 pr-3"><div className="col-12">Loading...</div></div>}
                        {!loading && questions && questions.length > 0 &&
                            <div className="row pt-1">
                                <div className="col-6">
                                    {selectedIndex > 0 &&
                                        <button
                                            type="button"
                                            disabled={uploading || loading}
                                            className={`submit-button ${uploading ? 'btn-processing' : ''}`}
                                            onClick={moveToPrevious}
                                        >
                                            Move to Previous Question
                                        </button>}
                                </div>
                                <div className="col-6">
                                    {selectedIndex < (questions.length - 1) &&
                                        <button
                                            type="button"
                                            disabled={uploading || loading}
                                            className={`float-right mr-3 submit-button ${uploading ? 'btn-processing' : ''}`}
                                            onClick={moveToNext}
                                        >
                                            Move to Next Question
                                        </button>}
                                    <ConfirmPopup />
                                    {selectedIndex === (questions.length - 1) &&
                                        <button
                                            type="button"
                                            disabled={uploading || loading}
                                            className={`float-right mr-3 submit-button ${uploading ? 'btn-processing' : ''}`}
                                            onClick={onConfirmSubmitHandler}
                                        >
                                            Submit
                                        </button>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment >
    );
};

export default PracticalExam;
