import { InputNumber } from "primereact/inputnumber";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import PracticalExamService from '../../../../services/practicalexam.service'
import AuthContext from "../../../../auth/auth-context";

const PracticalExamQuestionView = ({
    index,
    selectedIndex,
    input,
    changeHandler
}) => {
    const storyBoardTitle = input && input.storyBoardTitle;
    const storyBoardInfo = input && input.storyBoardInfo;
    const taskTitle = input && input.taskTitle;
    const taskInfo = input && input.taskInfo;
    const fileName = input && input.fileName;
    const [score, setScore] = useState(input && input.score);
    const [href, setHref] = useState();
    const [fileNotFound, setFileNotFound] = useState(false);

    const authContext = useContext(AuthContext);

    const assignScore = async (value) => {
        setScore(value);
        changeHandler(index, value);
    }

    const loadDocument = useCallback(async () => {
        let data = await PracticalExamService.GetFile(input.answerId, authContext);
        if (data.isSuccess && data.data && data.data != null) {
            setHref(`data:${data.data.contentType};base64,${data.data.filePath}`);
        } else {
            setFileNotFound(true);
        }
    }, [authContext, input.answerId]);

    useEffect(() => {
        loadDocument();
    }, [authContext, loadDocument, input.answerId]);

    return (
        <Fragment>
            <div className={selectedIndex === index ? 'd-block' : 'd-none'}>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{storyBoardTitle}</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{storyBoardInfo}</div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{taskTitle}</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{taskInfo}</div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Attachment</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10 value document-files">
                        {
                            fileName && fileName !== "" &&
                            <>
                                <div className="attachment-container">
                                    <div className="file">
                                        <a href={href} download={fileName}>{fileName}</a> {fileNotFound && <span className="text-danger">( File not found )</span>}
                                    </div>
                                </div>
                            </>
                        }
                        {
                            (fileName === undefined || fileName === null || fileName === "") &&
                            <>
                                <div className="attachment-container">
                                    <div className="file">
                                        <span className="text-danger">( No files attached )</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Score</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <InputNumber inputId="integeronly" maxFractionDigits={2} minFractionDigits={2} useGrouping={false} min={0} placeholder="Score" max={100.00} maxLength='3' format={false} value={score} onValueChange={(e) => assignScore(e.value)} className="w-full" />
                    </div>
                    <div className="col-2">
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PracticalExamQuestionView;
