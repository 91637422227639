import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AuthContext from "./auth/auth-context";
import Layout from "./components/layout";
import Questions from "./components/identity/questions/questions";
import Reset from "./components/identity/reset/reset";
import Logout from "./components/identity/logout/logout";
import QrCode from "./components/identity/mfa/qrcode";
import MFA from "./components/identity/mfa/mfa";
import MFAQ from "./components/identity/mfa/mfaq";
import VerifyOTP from "./components/identity/mfa/verifyotp";
import OTPQRVerify from "./components/identity/mfa/otpverify";
import ReConfigure from "./components/identity/mfa/reconfigure";
import Profile from "./components/identity/profile/profile";
import Role from "./components/identity/role/role";
import Permissions from "./components/identity/role/permissions";
import Answers from "./components/identity/profile/answers";
import ChangePassword from "./components/identity/profile/changepassword";
import EditProfile from "./components/identity/profile/editprofile";
import Dashboard from "./components/app/dashboard/dashboard";
import Catalogues from "./components/app/catalogues/catalogues";
import Bays from "./components/app/bays/bays";
import MasterAssessments from "./components/app/masters/assessments/assessments";
import Assessments from "./components/app/assessments/assessments";
import MCQAttendees from "./components/app/masters/assessments/mcqattendees";
import PracticalAttendees from "./components/app/masters/assessments/practicalattendees";
import MCQSessions from "./components/app/masters/assessments/mcqsessions";
import Attendees from "./components/app/masters/quiz/attendees";
import MCQExamView from "./components/app/masters/assessments/mcqexamview";
import PracticalExamView from "./components/app/masters/assessments/practicalexamview";
import ExamView from "./components/app/masters/quiz/examview";
import Support from "./components/app/support/support";
import AgentSupport from "./components/app/support/agentsupport";
import Courses from "./components/app/courses/courses";
import Report from "./components/app/reports/reports";
import QuizReport from "./components/app/reports/quizreport";
import AssessmentReport from "./components/app/reports/assessmentreport";
import AssessmentQuestionReport from "./components/app/reports/assessmentquestionreport";
import IncidentUserReport from "./components/app/reports/incidentuserreport";
import IncidentMISReport from "./components/app/reports/incidentmisreport";
import AddCatalogue from "./components/app/catalogues/add";
import Users from "./components/identity/users/users";
import AddCourse from "./components/app/courses/add";
import Documents from "./components/app/documents/documents";
import AddDocument from "./components/app/documents/add";
import AddSupport from "./components/app/support/add";
import Agent from "./components/app/masters/agents/agents";
import CourseIncidents from "./components/app/courses/courseIncidents";
import CourseUsers from "./components/app/courses/courseUsers";
import CourseDocs from "./components/app/courses/courseDocs";
import UserVMs from "./components/app/courses/userVMs";
import Learners from "./components/identity/learners/learners";
import UploadLearners from "./components/identity/learners/upload";
import Types from "./components/app/catalogues/types";
import Category from "./components/app/masters/category/category";
import AddAssessment from "./components/app/masters/assessments/add";
import ViewAssessment from "./components/app/masters/assessments/view";
import DocumentTypes from "./components/app/masters/doctypes/types";
import DocTypes from "./components/app/documents/types";
import AddDocumentType from "./components/app/masters/doctypes/add";
import MasterQuizzes from "./components/app/masters/quiz/quizzes";
import AddQuiz from "./components/app/masters/quiz/add";
import ViewQuiz from "./components/app/masters/quiz/view";
import Quizzes from "./components/app/quiz/quizzes";
import QuizMCQ from "./components/app/masters/quiz/mcq";
import QuizExam from "./components/app/quiz/exam";
import ReviewQuizExam from "./components/app/quiz/reviewexam";
import MCQExam from "./components/app/assessments/mcqexam";
import ReviewMCQExam from "./components/app/assessments/reviewmcqexam";
import PracticalExam from "./components/app/assessments/practicalexam";
import AssessmentMCQ from "./components/app/masters/assessments/mcq";
import AssessmentPractical from "./components/app/masters/assessments/practical";
import UploadUserVMs from "./components/app/courses/uploadUserVMs";
import UploadMCQ from "./components/app/masters/assessments/uploadMCQ";
import UploadQuizMCQ from "./components/app/masters/quiz/uploadQuizMCQ";
import RemoveUser from "./components/identity/learners/removeuser";
import AssessmentPracticalReport from "./components/app/reports/assessmentpracticalreport";
import ImpersonateUsers from "./components/app/impersonate/users";
// import ViewTicket from "./components/app/support/view";

function App() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.isLoggedIn === false) {
      navigate("login");
    }
  }, [authContext, navigate]);
  /*
    useEffect(() => {
      const handleContextmenu = e => {
        e.preventDefault()
      }
      const handleKeyEvents = e => {
        if (e.key === 123) {
          e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'I') {
          e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'C') {
          e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'J') {
          e.preventDefault();
        }
        if (e.ctrlKey && e.key === 'U') {
          e.preventDefault();
        }
      }
      document.addEventListener('contextmenu', handleContextmenu)
      document.addEventListener('keydown', handleKeyEvents)
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
        document.removeEventListener('keydown', handleContextmenu)
      }
    }, [])
  */
  return (
    <Layout>
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        {authContext.isLoggedIn && (<Route path="qrcode" exact element={<QrCode></QrCode>} />)}
        {authContext.isLoggedIn && (<Route path="configureqr/:code" exact element={<ReConfigure></ReConfigure>} />)}
        {authContext.isLoggedIn && (<Route path="mfa" exact element={<MFA></MFA>} />)}
        {authContext.isLoggedIn && (<Route path="mfaq" exact element={<MFAQ></MFAQ>} />)}
        {authContext.isLoggedIn && (<Route path="verifyotp" exact element={<VerifyOTP></VerifyOTP>} />)}
        {authContext.isLoggedIn && (<Route path="configmfaotp" exact element={<OTPQRVerify></OTPQRVerify>} />)}
        {authContext.isLoggedIn && (<Route path="questions" exact element={<Questions></Questions>} />)}
        {authContext.isLoggedIn && (<Route path="reset" exact element={<Reset></Reset>} />)}
        {authContext.isLoggedIn && (<Route path="logout" exact element={<Logout></Logout>} />)}
        {authContext.isLoggedIn && (<Route path="profile" exact element={<Profile></Profile>} />)}
        {authContext.isLoggedIn && (<Route path="profile/edit" exact element={<EditProfile></EditProfile>} />)}
        {authContext.isLoggedIn && (<Route path="roles" exact element={<Role></Role>} />)}
        {authContext.isLoggedIn && (<Route path="agents" exact element={<Agent></Agent>} />)}
        {authContext.isLoggedIn && (<Route path="permissions" exact element={<Permissions></Permissions>} />)}
        {authContext.isLoggedIn && (<Route path="profile" exact element={<Profile></Profile>} />)}
        {authContext.isLoggedIn && (<Route path="change" exact element={<ChangePassword></ChangePassword>} />)}
        {authContext.isLoggedIn && (<Route path="securityanswers" exact element={<Answers></Answers>} />)}
        {authContext.isLoggedIn && (<Route path="dashboard" exact element={<Dashboard></Dashboard>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues" exact element={<Catalogues></Catalogues>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/course/:id" exact element={<Catalogues></Catalogues>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/types/:categoryid" exact element={<Catalogues></Catalogues>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/add" exact element={<AddCatalogue></AddCatalogue>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/edit/:id" exact element={<AddCatalogue></AddCatalogue>} />)}
        {authContext.isLoggedIn && (<Route path="bays" exact element={<Bays></Bays>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments" exact element={<MasterAssessments></MasterAssessments>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/add" exact element={<AddAssessment></AddAssessment>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/edit/:id" exact element={<AddAssessment></AddAssessment>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/view/:id" exact element={<ViewAssessment></ViewAssessment>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/mcq/:id" exact element={<AssessmentMCQ></AssessmentMCQ>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/mcq/:id/import" exact element={<UploadMCQ></UploadMCQ>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/practical/:id" exact element={<AssessmentPractical></AssessmentPractical>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/attendees/mcq/:id" exact element={<MCQAttendees></MCQAttendees>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/attendees/practical/:id" exact element={<PracticalAttendees></PracticalAttendees>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/sessions/mcq/:id" exact element={<MCQSessions></MCQSessions>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/attendees/view/mcq/:id" exact element={<MCQExamView></MCQExamView>} />)}
        {authContext.isLoggedIn && (<Route path="master/assessments/attendees/view/practical/:id" exact element={<PracticalExamView></PracticalExamView>} />)}
        {authContext.isLoggedIn && (<Route path="assessments" exact element={<Assessments></Assessments>} />)}
        {authContext.isLoggedIn && (<Route path="assessments/:id" exact element={<Assessments></Assessments>} />)}
        {authContext.isLoggedIn && (<Route path="assessments/start/M/:id" exact element={<MCQExam></MCQExam>} />)}
        {authContext.isLoggedIn && (<Route path="assessments/review/M/:id" exact element={<ReviewMCQExam></ReviewMCQExam>} />)}
        {authContext.isLoggedIn && (<Route path="assessments/start/P/:id" exact element={<PracticalExam></PracticalExam>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes" exact element={<MasterQuizzes></MasterQuizzes>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/add" exact element={<AddQuiz></AddQuiz>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/edit/:id" exact element={<AddQuiz></AddQuiz>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/view/:id" exact element={<ViewQuiz></ViewQuiz>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/mcq/:id" exact element={<QuizMCQ></QuizMCQ>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/mcq/:id/import" exact element={<UploadQuizMCQ></UploadQuizMCQ>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/attendees/:id" exact element={<Attendees></Attendees>} />)}
        {authContext.isLoggedIn && (<Route path="master/quizzes/attendees/view/:id" exact element={<ExamView></ExamView>} />)}
        {authContext.isLoggedIn && (<Route path="quizzes" exact element={<Quizzes></Quizzes>} />)}
        {authContext.isLoggedIn && (<Route path="quizzes/start/:id" exact element={<QuizExam></QuizExam>} />)}
        {authContext.isLoggedIn && (<Route path="quizzes/review/:id" exact element={<ReviewQuizExam></ReviewQuizExam>} />)}
        {authContext.isLoggedIn && (<Route path="support" exact element={<Support></Support>} />)}
        {authContext.isLoggedIn && (<Route path="support/agent" exact element={<AgentSupport></AgentSupport>} />)}
        {authContext.isLoggedIn && (<Route path="support/add" exact element={<AddSupport></AddSupport>} />)}
        {/* {authContext.isLoggedIn && (<Route path="support/view/:id" exact element={<ViewTicket></ViewTicket>} /> )} */}
        {authContext.isLoggedIn && (<Route path="courses" exact element={<Courses></Courses>} />)}
        {authContext.isLoggedIn && (<Route path="courses/add" exact element={<AddCourse></AddCourse>} />)}
        {authContext.isLoggedIn && (<Route path="courses/edit/:id" exact element={<AddCourse></AddCourse>} />)}
        {authContext.isLoggedIn && (<Route path="courses/incidents/:id" exact element={<CourseIncidents></CourseIncidents>} />)}
        {authContext.isLoggedIn && (<Route path="courses/users/:id" exact element={<CourseUsers></CourseUsers>} />)}
        {authContext.isLoggedIn && (<Route path="courses/documents/:id" exact element={<CourseDocs></CourseDocs>} />)}
        {authContext.isLoggedIn && (<Route path="courses/users/vms/:id" exact element={<UserVMs></UserVMs>} />)}
        {authContext.isLoggedIn && (<Route path="courses/users/vms/import/:id" exact element={<UploadUserVMs></UploadUserVMs>} />)}
        {authContext.isLoggedIn && (<Route path="reports" exact element={<Report></Report>} />)}
        {authContext.isLoggedIn && (<Route path="reports/quizreport" exact element={<QuizReport></QuizReport>} />)}
        {authContext.isLoggedIn && (<Route path="reports/assessmentreport" exact element={<AssessmentReport></AssessmentReport>} />)}
        {authContext.isLoggedIn && (<Route path="reports/assessmentreport/questions" exact element={<AssessmentQuestionReport></AssessmentQuestionReport>} />)}
        {authContext.isLoggedIn && (<Route path="reports/assessmentreport/practical" exact element={<AssessmentPracticalReport></AssessmentPracticalReport>} />)}
        {authContext.isLoggedIn && (<Route path="reports/incidentuserreport" exact element={<IncidentUserReport></IncidentUserReport>} />)}
        {authContext.isLoggedIn && (<Route path="reports/incidentreport" exact element={<IncidentMISReport></IncidentMISReport>} />)}
        {authContext.isLoggedIn && (<Route path="users" exact element={<Users></Users>} />)}
        {authContext.isLoggedIn && (<Route path="documenttypes" exact element={<DocumentTypes></DocumentTypes>} />)}
        {authContext.isLoggedIn && (<Route path="documenttypes/add" exact element={<AddDocumentType></AddDocumentType>} />)}
        {authContext.isLoggedIn && (<Route path="documenttypes/edit/:id" exact element={<AddDocumentType></AddDocumentType>} />)}
        {authContext.isLoggedIn && (<Route path="doctypes" exact element={<DocTypes></DocTypes>} />)}
        {authContext.isLoggedIn && (<Route path="documents" exact element={<Documents></Documents>} />)}
        {authContext.isLoggedIn && (<Route path="documents/:typeid" exact element={<Documents></Documents>} />)}
        {authContext.isLoggedIn && (<Route path="documents/:typeid/add" exact element={<AddDocument></AddDocument>} />)}
        {authContext.isLoggedIn && (<Route path="documents/:typeid/edit/:id" exact element={<AddDocument></AddDocument>} />)}
        {authContext.isLoggedIn && (<Route path="learners" exact element={<Learners></Learners>} />)}
        {authContext.isLoggedIn && (<Route path="types" exact element={<Types></Types>} />)}
        {authContext.isLoggedIn && (<Route path="learners/upload" exact element={<UploadLearners></UploadLearners>} />)}
        {authContext.isLoggedIn && (<Route path="learners/remove" exact element={<RemoveUser></RemoveUser>} />)}
        {authContext.isLoggedIn && (<Route path="categories" exact element={<Category></Category>} />)}
        {authContext.isLoggedIn && (<Route path="impersonateuser" exact element={<ImpersonateUsers></ImpersonateUsers>} />)}
        <Route path="/*" element={<Navigate to="/" push={true} />} />
      </Routes>
    </Layout>
  );
}

export default App;
