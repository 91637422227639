import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../auth/auth-context";
import RoleService from "../../../services/role.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";

const Role = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [roleId, setRoleId] = useState(0);
  const [roleName, setRoleName] = useState();
  const [active, setActive] = useState();
  const [isImpersonateAvailable, setIsImpersonateAvailable] = useState(false);
  const authContext = useContext(AuthContext);

  const [roleNameIsValid, setRoleNameIsValid] = useState(true);

  const roleNameRef = useRef();

  const assignRole = async (e) => {
    if (roles.filter((f) => f.id === e).length > 0) {
      setRoleNameIsValid(true);
      let selectedRoleData = roles.filter((f) => f.id === e)[0];
      setRoleId(selectedRoleData.data.id);
      setRoleName(selectedRoleData.data.roleName);
      setIsImpersonateAvailable(selectedRoleData.data.isImpersonateAvailable);
      roleNameRef.current.value = selectedRoleData.data.roleName;
      setActive(selectedRoleData.data.isActive);
    }
  };

  const loadRoles = useCallback(async () => {
    setLoading(true);
    const data = await RoleService.GetAll(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setRoles(
        data.data.map((m) => ({
          id: m.id,
          key: m.id,
          label: m.roleName,
          data: m,
        }))
      );
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles, authContext]);

  const clearData = () => {
    setSelectedRole(0);
    setRoleId(0);
    roleNameRef.current.value = "";
    setActive(true);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let roleNameValue = roleNameRef.current.value;
    if (!roleNameValue) {
      setRoleNameIsValid(false);
    }
    if (roleNameIsValid) {
      setLoading(true);
      try {
        let payload = {
          instanceId: 1,
          roleName: roleNameValue,
          isActive: active,
          isImpersonateAvailable: isImpersonateAvailable,
        };
        if (roleId > 0) {
          payload.id = roleId;
        }
        let data = await RoleService.Save(payload, authContext);
        if (data.isSuccess) {
          clearData();
          await loadRoles();
          AlertService.success("Role details saved successfully!");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete the selected role?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await RoleService.Remove(selectedRole, authContext);
    if (data.isSuccess) {
      clearData();
      await loadRoles();
      AlertService.success("Role details deleted successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onCancelHandler = async (e) => {
    e.preventDefault();
    clearData();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <h2>Roles</h2>
        <div className="row m-0">
          <div className="col-lg-4 d-none d-lg-block">
            <Tree
              value={roles}
              selectionMode="single"
              selectionKeys={selectedRole}
              onSelectionChange={(e) => {
                setSelectedRole(e.value);
                assignRole(e.value);
              }}
              className="w-full"
              loading={loading}
            />
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="row pt-4 d-block d-lg-none">
              <div className="col-12">
                <select
                  className="form-control"
                  value={selectedRole}
                  onChange={(e) => {
                    setSelectedRole(Number(e.target.value));
                    assignRole(Number(e.target.value));
                  }}
                >
                  {roles &&
                    roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <form onSubmit={onSubmitHandler}>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Role Name
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Role Name"
                    maxLength="150"
                    defaultValue={roleName}
                    ref={roleNameRef}
                    onChange={() => setRoleNameIsValid(true)}
                  />
                  {!roleNameIsValid && (
                    <p className="error">Please provide role name</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Impersonate Available</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Checkbox
                    onChange={(e) => setIsImpersonateAvailable(e.checked)}
                    checked={isImpersonateAvailable}
                  ></Checkbox>
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">Active</div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <Checkbox
                    onChange={(e) => setActive(e.checked)}
                    checked={active}
                  ></Checkbox>
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-2 mb-3">
                <div className="col-md-12 col-sm-12">
                  <div className="text-center pt-4">
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={loading}
                    >
                      Submit
                    </button>
                    <ConfirmPopup />
                    {selectedRole > 0 && (
                      <button
                        type="button"
                        className="remove-button ml-3"
                        onClick={onConfirmDeleteHandler}
                        disabled={loading}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      type="button"
                      className="cancel-button ml-3"
                      onClick={onCancelHandler}
                      disabled={loading}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Role;
