import AlertService from "../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import DocumentViewer from "../documents/viewer";
import CourseService from '../../../services/course.service'

const DocViewer = ({ doc }) => {
    const [document, setDocument] = useState();
    const [contentType, setContentType] = useState();
    const authContext = useContext(AuthContext);

    const downloadFile = useCallback(async () => {
        setDocument(null);
        const data = await CourseService.GetFile(doc.documentId, authContext);
        if (data) {
            if (data.isSuccess) {
                // setDocument(`data:${data.data.contentType};base64,${data.data.filePath}`);
                setContentType(data.data.contentType);
                setDocument(data.data.filePath);
            } else {
                AlertService.error(data.errorMessage);
            }
        }
    }, [doc.documentId, authContext]);

    useEffect(() => {
        downloadFile();
    }, [downloadFile, doc.documentId, authContext]);

    return (
        <Fragment>
            {document &&
                <DocumentViewer src={document} contentType={contentType}></DocumentViewer>
            }
        </Fragment>
    )
}

export default DocViewer;