import { Fragment, useContext, useState } from "react";
import mammoth from 'mammoth';
import SupportService from "../../../services/support.service";
import AuthContext from "../../../auth/auth-context";
import DocumentViewer from "../documents/viewer";
import { Sidebar } from "primereact/sidebar";
import AlertService from "../../../services/alert.service";

const Attachment = ({ no, attachment, isComment }) => {
  no++;
  const authContext = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [pdfData, setPDFData] = useState();
  const [contentType, setContentType] = useState();
  const [loading, setLoading] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const fromComment = isComment ?? false;
  const [htmlContent, setHtmlContent] = useState('');

  const downloadFile = async () => {
    setLoading(true);
    setShowPDF(false);
    setShowDoc(false);
    setShowImage(false);
    setPDFData(undefined);
    setHtmlContent(undefined);
    let data;
    if (fromComment) {
      data = await SupportService.GetCommentFile(attachment.id, authContext);
    } else {
      data = await SupportService.GetFile(attachment.id, authContext);
    }
    if (data.isSuccess) {
      if (data.data.contentType === 'application/pdf') {
        setShowPDF(true);
        setContentType(data.data.contentType);
        // setPDFData(`data:${data.data.contentType};base64,${data.data.filePath}`);
        setPDFData(data.data.filePath);
      } else if (data.data.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        setShowDoc(true);
        const byteCharacters = atob(`${data.data.filePath}`);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        const reader = new FileReader();
        reader.onload = async function (e) {
          const arrayBuffer = reader.result;
          const result = await mammoth.convertToHtml({ arrayBuffer });
          setHtmlContent(result.value); // Set HTML content
        }
        reader.readAsArrayBuffer(blob);
      } else {
        setShowImage(true);
        // setPDFData(`data:${data.data.contentType};base64,${data.data.filePath}`);
        setPDFData(data.data.filePath);
      }
      setVisible(true);
    } else {
      AlertService.error(data.errorMessage);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="attachment">
        {no}.{" "}
        <button className={`btn-icon ${loading ? 'loading' : ''}`} onClick={downloadFile} title="Click on the filename to preview the file.">
          {attachment.fileName}{loading ? ' ...' : ''}
        </button>
        <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
          {pdfData && showPDF && <DocumentViewer src={pdfData} contentType={contentType}></DocumentViewer>}
          {htmlContent && showDoc && <div dangerouslySetInnerHTML={{ __html: htmlContent }} ></div>}
          {pdfData && showImage && <img alt="" style={{ "width": "100%", "height": "auto" }} src={pdfData} ></img>}
        </Sidebar>
      </div>
    </Fragment>
  );
};

export default Attachment;
